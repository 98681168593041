'use strict';

import Dispatcher       from '../Dispatcher';
import Constants        from '../Constants';
import {doFetch}        from "../../utils/CommonUtils";
import Routes           from '../../utils/Routes';
import FluxEventEmitter from '../FluxEventEmitter';
import assign           from 'object-assign';

const ActionTypes = Constants.ActionTypes.Breadcrumb;
const ChangeEvent = 'BreadCrumb';

const breadcrumbs = {};

const getAlbumId = (breadcrumbs) => {
	const videoBreadcrumb = breadcrumbs.find((breadcrumb) => breadcrumb.type === 'VideoObject');
	if(videoBreadcrumb) {
		const url = videoBreadcrumb.url;
		const videoId = url.split("/videos/")[1]?.split("-")[0];
		return videoId;
	}
};

function loadBreadcrumb(breadcrumbType, payload, breadcrumbKey) {
	if (breadcrumbs[breadcrumbKey] &&
		(payload.albumId === 0 || (payload.albumId && payload.albumId === getAlbumId(breadcrumbs[breadcrumbKey])))) {
		BreadcrumbStore.emit(ChangeEvent, breadcrumbType, breadcrumbKey);
	} else {
		doFetch(Routes.getRoute(Routes.Names.BREADCRUMB, { breadcrumbType }), { payload }, Constants.HttpMethods.POST, true)
			.then((data) => {
				breadcrumbs[breadcrumbKey] = data.data.breadcrumb;
				BreadcrumbStore.emit(ChangeEvent, breadcrumbType, breadcrumbKey);
			});
	}
}

const BreadcrumbStore = assign({}, FluxEventEmitter.prototype, {
	addBreadcrumbChangeListener: function(callback) {
		this.on(ChangeEvent, callback);
	},

	removeBreadcrumbChangeListener: function(callback) {
		this.removeListener(ChangeEvent, callback);
	},

	getBreadcrumb: function(key) {
		return breadcrumbs[key];
	},
});

BreadcrumbStore.dispatchToken = Dispatcher.register(function(action) {
	switch (action.type) {
		case ActionTypes.LOAD_BREADCRUMB:
			loadBreadcrumb(action.breadcrumbType, action.data, action.breadcrumbKey);
			break;
		default:
	}
});

export default BreadcrumbStore;
