class Translations {

	/**
	 * @param {string} key
	 * @param {...string} args
	 *
	 * @return string
	 */
	get(key, ...args) {
		const translation = window.translations[key];
		let out = (typeof translation === 'string' ? translation : '');

		if (args.length > 0) {
			for (let i = 0; i < args.length; i++) {
				out = out.replace('%s', args[i]);
			}
		}

		return out;
	}

}

const wrapperInstance = new Translations();

export default wrapperInstance;
